import { GetterTree } from 'vuex';
import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  error(state: State): string | null;
  isLoading(state: State): boolean;
  address(state: State): string | null;
  starkPublicKey(state: State): string | null;
  violations(state: State): string | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
    error: (state) => state.error,
    isLoading: (state) => state.isLoading,
    address: (state) => state.address,
    starkPublicKey: (state) => state.starkPublicKey,
    violations: (state) => state.violations
};