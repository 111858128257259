import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { ethEnabled } from '@/services';

import HomePage from '../views/HomePage.vue'
import InstallMetaMask from '../views/InstallMetaMask.vue'
import LoginPage from '../views/Login.vue'
import CompletePage from '../views/CompletePage.vue'
import TransactionList from '@/components/transaction/List.vue'
import { store as Store } from '@/store';

//import { kuzzle } from '@/services';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/install-meta-mask',
    name: 'InstallMetaMask',
    component: InstallMetaMask,
    meta: { 
      public: true,
      disableMetaMaskCheck: true,
      layout: 'full-layout'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { 
      public: true,
      layout: 'full-layout'
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionList
  },
  {
    path: '/complete/:txhash',
    name: 'complete',
    component: CompletePage
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'Home' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(!to.matched.some(route => route.meta.disableMetaMaskCheck)){
    if(ethEnabled() == false){
      //metamask not installed redirect
      return next({ name: 'InstallMetaMask' })
    }
  }
  const address = Store.state.transactionConnect.address;
  if (!to.matched.some(route => route.meta.public)) {
    if(address == null){
      return next({ name: 'Login' });
    }
  }
  next()
})

export default router
