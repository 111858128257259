import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { MutationTypes } from './mutation-types';
import { ActionTypes } from './action-types';

import { link } from '@/services';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.CONNECT](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.CONNECT]({ commit }) {
    return new Promise(() => {
        commit(MutationTypes.SET_ERROR, "");
        commit(MutationTypes.TOGGLE_LOADING, undefined);
        link.setup({}).then((value) => {
            commit(MutationTypes.SET_ADDRESS, value.address);
            commit(MutationTypes.SET_STARK_PUBLIC_KEY, value.starkPublicKey);
            return true;
        }).catch((e) => {
            console.log(e);
            commit(MutationTypes.SET_ERROR, e.message);
            return true;
        });
    });
  }
};