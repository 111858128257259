
import {
  IonImg,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from "@ionic/vue";
import { ethEnabled } from "@/services";
import { defineComponent } from "vue";

import { INTALL_META_MASK_URL } from "@/config";

export default defineComponent({
  name: "InstallMetaMask",
  components: {
    IonImg,
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonCardHeader,
    IonCardTitle,
    IonButton,
  },
  methods: {
    openMetaMask() {
      window.open(INTALL_META_MASK_URL, "_blank");
    },
  },
  computed: {},
  setup() {
    ethEnabled();
  },
});
