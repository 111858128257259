
import { cryptography } from './cryptography';

class CookieData {
    data: string;
    expires: number;
    constructor(data: string, expires: number){
        this.data = data;
        this.expires = expires;
    }
}

class SecureStorageCookie{
    setCookie(name: string, data: string, lifespan: number){
        const time = Date.now();
        const expires = new Date(time);
        expires.setSeconds(expires.getSeconds() + lifespan)
        const cookieData = new CookieData(data, expires.getTime());
        if (lifespan == 0) {
            cookieData.expires = 0;
        }
        localStorage.setItem(name, cryptography.encrypt(JSON.stringify(cookieData)));
        localStorage.getItem(name);
    }
    getCookie(name: string){
        const value = localStorage.getItem(name);
        if(value){
            const decryptedValue = cryptography.decrypt(value);
            const cookieData: CookieData = JSON.parse(decryptedValue);
            const time = Date.now();
            if(cookieData.expires > 0 && time > cookieData.expires){
                this.removeCookie(name);
                return null;
            }
            return cookieData.data;
        }
        return null;
    }
    async removeCookie(name: string){
        localStorage.removeItem(name);
    }
}

export const secureStorageCookie = new SecureStorageCookie();