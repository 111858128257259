
import { 
    Kuzzle, 
    WebSocket,
} from 'kuzzle-sdk';

import { KUZZLE_CONNECTION_HOST, KUZZLE_CONNECTION_PORT, KUZZLE_CONNECTION_DBINDEX, KUZZLE_CONNECTION_DBCOLLECTION } from '@/config';

const DBINDEX:string = KUZZLE_CONNECTION_DBINDEX ;
const DBCOLLECTION:string = KUZZLE_CONNECTION_DBCOLLECTION;

const port = typeof KUZZLE_CONNECTION_PORT === 'string' ? 443 : KUZZLE_CONNECTION_PORT;

const kuzzle = new Kuzzle(
  new WebSocket(KUZZLE_CONNECTION_HOST, { port: port, autoReconnect: true })
);

const example = new Date().getTime();

export {
    kuzzle,
    DBINDEX,
    DBCOLLECTION,
    example
}