import { MutationTree } from 'vuex';

import { State } from './state';
import { MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [MutationTypes.RESET](state: S): void;
    [MutationTypes.SET_STATUS](state: S, payload: boolean): void;
    [MutationTypes.SET_ERROR](state: S, payload: string | null): void;
    [MutationTypes.SET_ROOM_ID](state: S, payload: string | boolean): void;
    [MutationTypes.TOGGLE_LOADING](state: S): void;
    [MutationTypes.SET_SUBSCRIBED](state: S, payload: boolean): void;
    [MutationTypes.SET_MESSAGE](state: S, payload: string | null): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state: State) {
    state.status = false;
    state.roomId = false;
  },
  [MutationTypes.SET_STATUS](state: State, payload: boolean) {
    state.status = payload;
  },
  [MutationTypes.SET_SUBSCRIBED](state: State, payload: boolean) {
    state.subscribed = payload;
  },
  [MutationTypes.SET_ERROR](state: State, payload: string | null) {
    state.error = payload;
  },
  [MutationTypes.SET_ROOM_ID](state: State, payload: string | boolean) {
    state.roomId = payload;
  },
  [MutationTypes.TOGGLE_LOADING](state: State) {
    state.isLoading = !state.isLoading;
  },
  [MutationTypes.SET_MESSAGE](state: State, payload: string | null) {
    state.message = payload;
  },
};