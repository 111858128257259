import { WALLET_ADDRESS } from '@/config';

import web3 from 'web3';

const weiToEth = (wei:string) => {
    return web3.utils.fromWei(wei, 'ether');
}

const ethToWei = (eth:number) => {
    return web3.utils.toWei(eth.toString(), 'ether');
}

const ethEnabled = () => {
    if (typeof window.ethereum !== 'undefined') {
        return true;
    }
    return false;
}

const requestAccounts = async () => {
    if (window.ethereum) {
        return window.ethereum.request({method: 'eth_requestAccounts'});
    }
    return new Promise(() => {
        return false;
    });
}

const requestAccountPermissions = async () => {
    if (window.ethereum) {
        return window.ethereum.request({method: 'eth_requestPermissions'});
    }
    return new Promise(() => {
        return false;
    });
}

const payment = async (val:number) => {
    const cost = (val*1e18).toString(16);
    const transactionParameters = {
        to: WALLET_ADDRESS, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: cost, // Only required to send ether to the recipient from the initiating external account.
        //chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    return window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
    });
}

export { ethEnabled, payment, requestAccounts, requestAccountPermissions, weiToEth, ethToWei };