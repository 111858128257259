export const state: State = {
    error: '',
    isLoading: false,
    status: false,
    subscribed: false,
    roomId: false,
    message: ''
}

export type State = {
    error: string | null;
    isLoading: boolean;
    status: boolean;
    subscribed: boolean;
    roomId: string | boolean;
    message: string | null
}