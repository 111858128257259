
import { mapGetters, mapActions } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes } from '@/store/modules/transaction/connect/action-types';
import { 
    IonButton,
    loadingController
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionConnectButton',
  components: { 
    IonButton
  },
  computed: {
    ...mapGetters('transactionConnect', ['address','isLoading','error','violations']),
    connectText(){
      if(this.address != null){
        return `Disconnect`;
      }
      return "Connect";
    }
  },
  watch: {
    isLoading(value){
      if(value){
        this.presentLoading();
      }else{
        loadingController.dismiss();
      }
    },
    address(value){
      if(value != null){
        this.router.push({ name: 'Home' });
      }else{
        this.router.push({ name: 'Login' });
      }
    }
  },
  methods: {
    ...mapActions('transactionConnect',[
      ActionTypes.CONNECT,
      ActionTypes.DISCONNECT
    ]),
    connectNow(){
      if(this.address){
        this.TRANSACTION_DISCONNECT();
      }else{
        this.TRANSACTION_CONNECT();
      }
    },
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Awaiting MetaMask Response',
        });
      await loading.present();
    },
  },
  setup(){
    const router = useRouter();
    return {
      router
    }
  }
});
