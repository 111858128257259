import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { MutationTypes } from './mutation-types';
import { ActionTypes } from './action-types';

import { DBINDEX, DBCOLLECTION, kuzzle } from '@/services';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.SET_LISTENER](
    { commit }: AugmentedActionContext,
    address: string
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SET_LISTENER]({ commit }, address) {
    
    await kuzzle.connect();

    const filters = { equals: {
      address: address
    }};
    const callback = (res:any) => {
      console.log(res);
    }
    const room = await kuzzle.realtime.subscribe(DBINDEX, DBCOLLECTION, filters, callback);
    console.log(room);

    try{
    const roomid = await kuzzle.realtime.subscribe(DBINDEX, DBCOLLECTION, {
      equals: {
        address: address
      }
    }, (item: any) => {
      commit(MutationTypes.SET_MESSAGE, item.message);
      console.log(item);
    });
    console.log(roomid);
    }catch(e){
      console.log(e);
    }

    return new Promise(() => {
      console.log("SET_LISTENER");

      
      kuzzle.connect().then(() => {
        console.log("SET_LISTENER connected");
        console.log(address);

        try {
          console.log("try");
          const roomid = kuzzle.realtime.subscribe(DBINDEX, DBCOLLECTION, {
            equals: {
              address: address
            }
          }, (item: any) => {
            commit(MutationTypes.SET_MESSAGE, item.message);
            console.log(item);
          }).then((roomid: string) => {
            console.log(roomid);
          });
          console.log(roomid);
        }catch(e){
          console.log(e);
        }finally{
          console.log("final");
        }

      }).catch((e) => {
        console.log(e);
        commit(MutationTypes.SET_ERROR, e.message);
        return true;
      });
    });
  },
  async [ActionTypes.UNSET_LISTENER]({ commit, state }) {
    return new Promise(() => {
      kuzzle.connect().then(async () => {
        if (typeof state.roomId === 'string') {
          await kuzzle.realtime.unsubscribe(state.roomId);
          commit(MutationTypes.SET_ROOM_ID, false);
        }
        return true;
      }).catch((e) => {
        console.log(e);
        commit(MutationTypes.SET_ERROR, e.message);
        return true;
      });
    });
  },
};