const CRYPTOKEY = process.env.VUE_APP_ENTRYPOINT || "$VUE_APP_CRYPTOKEY";

const WALLET_ADDRESS = process.env.VUE_APP_WALLET_ADDRESS || "$VUE_APP_WALLET_ADDRESS";

const MINTING_COST = process.env.VUE_APP_MINTING_COST ? parseFloat(process.env.VUE_APP_MINTING_COST) : "$VUE_APP_MINTING_COST";
const MINTING_LIMIT = process.env.VUE_APP_MINTING_LIMIT ? parseFloat(process.env.VUE_APP_MINTING_LIMIT) : "$VUE_APP_MINTING_LIMIT";

const KUZZLE_CONNECTION_HOST = process.env.VUE_APP_KUZZLE_CONNECTION_HOST || "$VUE_APP_KUZZLE_CONNECTION_HOST";
const KUZZLE_CONNECTION_PORT = process.env.VUE_APP_KUZZLE_CONNECTION_PORT ? parseInt(process.env.VUE_APP_KUZZLE_CONNECTION_PORT) : "$VUE_APP_KUZZLE_CONNECTION_PORT";

const KUZZLE_CONNECTION_DBINDEX = process.env.VUE_APP_KUZZLE_CONNECTION_DBINDEX !== undefined ? process.env.VUE_APP_KUZZLE_CONNECTION_DBINDEX : "$VUE_APP_KUZZLE_CONNECTION_DBINDEX";
const KUZZLE_CONNECTION_DBCOLLECTION = process.env.VUE_APP_KUZZLE_CONNECTION_DBCOLLECTION !== undefined ? process.env.VUE_APP_KUZZLE_CONNECTION_DBCOLLECTION : "$VUE_APP_KUZZLE_CONNECTION_DBCOLLECTION";

const ETHERSCAN_URL = process.env.VUE_APP_ETHERSCAN_URL || "$VUE_APP_ETHERSCAN_URL";

const INTALL_META_MASK_URL = process.env.VUE_APP_INSTALL_METAMASK_URL || "$VUE_APP_INSTALL_METAMASK_URL";

const DISCORD_URL = process.env.VUE_APP_DISCORD_INVITE_URL || "$VUE_APP_DISCORD_INVITE_URL";

const IMX_MARKETPLACE_URL = process.env.VUE_APP_IMX_MARKETPLACE_URL || "$VUE_APP_IMX_MARKETPLACE_URL";
const IMX_CONTRACT = process.env.VUE_APP_IMX_CONTRACT || "$VUE_APP_IMX_CONTRACT";


export {
    CRYPTOKEY,
    WALLET_ADDRESS,
    KUZZLE_CONNECTION_HOST,
    KUZZLE_CONNECTION_PORT,
    KUZZLE_CONNECTION_DBINDEX,
    KUZZLE_CONNECTION_DBCOLLECTION,
    ETHERSCAN_URL,
    INTALL_META_MASK_URL,
    DISCORD_URL,
    MINTING_COST,
    MINTING_LIMIT,
    IMX_MARKETPLACE_URL,
    IMX_CONTRACT
}