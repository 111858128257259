
import { useRouter } from "vue-router";

//import { IonButton, IonInput, IonItem } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayingCards",
  components: {
  },
  props: {
    quantity: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    quantityOutout() {
      if(this.quantity >= 27) {
        return 27;
      }
      return this.quantity;
    },
    quantityClass(){
      if(this.quantity >= 5){
        return "quantity-5";
      }
      return "quantity-" + this.quantity;
    },
    deckClass(){
      if(this.quantity >= 5){
        return "deck";
      }
      return "spread";
    }
  },
  methods: {
    getCardNumber(index:number){
      if(index >= 27){
        return this.quantity;
      }
      return index
    }
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
});
