import { secureStorageCookie } from '@/services';

const address = secureStorageCookie.getCookie('address');
const starkPublicKey  = secureStorageCookie.getCookie('starkPublicKey');
const ethNetwork  = secureStorageCookie.getCookie('ethNetwork');

export const state: State = {
    error: '',
    isLoading: false,
    address: address,
    starkPublicKey: starkPublicKey,
    ethNetwork: ethNetwork,
    violations: null,
}

export type State = {
    error: string | null;
    isLoading: boolean;
    address: string | null;
    starkPublicKey: string | null;
    ethNetwork: string | null;
    violations: string | null;
}