import { ImmutableXClient, Link } from '@imtbl/imx-sdk';

//const linkAddress = 'https://link.x.immutable.com';
//const apiAddress = 'https://api.x.immutable.com/v1';

// Ropsten Testnet
const linkAddress = 'https://link.ropsten.x.immutable.com';
const apiAddress = 'https://api.ropsten.x.immutable.com/v1';

// Link SDK
const link = new Link(linkAddress);

// IMX Client
const client = ImmutableXClient.build({ publicApiUrl: apiAddress });

export {
    link,
    client
}