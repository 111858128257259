import { MutationTree } from 'vuex';

import { State } from './state';
import { MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [MutationTypes.RESET](state: S): void;
    [MutationTypes.SET_TX_HASH](state: S, payload: string): void;
    [MutationTypes.SET_ERROR](state: S, payload: string | null): void;
    [MutationTypes.SET_VIOLATIONS](state: S, payload: string): void;
    [MutationTypes.TOGGLE_LOADING](state: S): void;
    [MutationTypes.SET_CONNECTION_STATUS](state: S, payload: boolean): void;
    [MutationTypes.SET_REMAINING_COUNT](state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state: State) {
    state.txHash = null;
  },
  [MutationTypes.SET_TX_HASH](state: State, payload: string) {
    state.txHash = payload;
  },
  [MutationTypes.SET_ERROR](state: State, payload: string | null) {
    state.error = payload;
  },
  [MutationTypes.SET_VIOLATIONS](state: State, payload: string) {
    state.violations = payload;
  },
  [MutationTypes.TOGGLE_LOADING](state: State) {
    state.isLoading = !state.isLoading;
  },
  [MutationTypes.SET_CONNECTION_STATUS](state: State, payload: boolean) {
    state.connected = payload;
  },
  [MutationTypes.SET_REMAINING_COUNT](state: State, payload: number) {
    state.remaining = payload;
  }
};