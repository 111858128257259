import { secureStorageCookie } from '@/services';

const address = secureStorageCookie.getCookie('address');

export const state: State = {
    error: '',
    isLoading: false,
    address: address,
    ethNetwork: '',
    violations: null,
}

export type State = {
    error: string | null;
    isLoading: boolean;
    address: string | null;
    ethNetwork: string | null;
    violations: string | null;
}