
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from "@ionic/vue";
import { ethEnabled } from "@/services";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { ETHERSCAN_URL } from "@/config"; 

export default defineComponent({
  name: "CompletePage",
  components: {
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonCardHeader,
    IonCardTitle,
    IonButton,
  },
  methods:{
    openEtherscan(txhash: string) {
      window.open(`${ETHERSCAN_URL}${txhash}`, "_blank");
    },
    goToTransactions() {
      this.router.push({ name: "transactions" });
    },
  },
  computed: {},
  setup() {
    ethEnabled();
    const router = useRouter();
    return {
      router,
      txhash: router.currentRoute.value.params.txhash,
    };
  },
});
