import CryptoJS from 'crypto-js';
import { CRYPTOKEY } from '@/config';

class Cryptography{
    private key: string;
    constructor(key: string){
        this.key = key;
    }
    encrypt(value: string){
        return CryptoJS.AES.encrypt(value, this.key).toString();
    }
    decrypt(value: string){
        const bytes  = CryptoJS.AES.decrypt(value, this.key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}

export const cryptography = new Cryptography(CRYPTOKEY);