import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { MutationTypes } from './mutation-types';
import { ActionTypes } from './action-types';

import { transactions } from '@/types';
import { 
  payment,
  kuzzle,
  DBINDEX,
  //DBCOLLECTION
  requestAccounts
} from '@/services';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.PURCHASE](
    { commit }: AugmentedActionContext,
    data: transactions
  ): Promise<boolean>;
  [ActionTypes.LISTEN_FOR_CONNECTION](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.PURCHASE]({ commit }, data) {
    return new Promise(() => {
        commit(MutationTypes.SET_ERROR, "");
        commit(MutationTypes.TOGGLE_LOADING, undefined);
        requestAccounts().then(() => {
          payment(data.value).then((receipt) => {
            commit(MutationTypes.SET_TX_HASH, receipt);
            commit(MutationTypes.TOGGLE_LOADING, undefined);
            const cost = (data.value*1e18).toString(16);
            kuzzle.connect().then(async () => {
              kuzzle.document.create(DBINDEX, "transactions",{
                "address": window.ethereum.selectedAddress,
                "status": "POSTED",
                "txhash": receipt,
                "value": cost,
                "quantity": data.quantity,
              },receipt);
            });
            return true;
          }).catch((e) => {
            commit(MutationTypes.SET_ERROR, e.message);
            commit(MutationTypes.TOGGLE_LOADING, undefined);
            if(e.code == -32602){
              console.log("Unlock MetaMask");
            }
            return false;
          });
        }).catch((e) => {
          commit(MutationTypes.SET_ERROR, e.message);
          commit(MutationTypes.TOGGLE_LOADING, undefined);
          return true;
        });
    });
  },
  async [ActionTypes.LISTEN_FOR_CONNECTION]({ commit }) {
    kuzzle.addListener("disconnected", () => {
      console.log("event disconnected");
      commit(MutationTypes.SET_CONNECTION_STATUS, false);
    });
    kuzzle.addListener("connected", () => {
      console.log("event connected");
      commit(MutationTypes.SET_CONNECTION_STATUS, true);
    });
    kuzzle.addListener("reconnected", () => {
      console.log("event reconnected");
      commit(MutationTypes.SET_CONNECTION_STATUS, true);
    }); 
    return new Promise(() => {
      return true;
    });
  },
};