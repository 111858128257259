import { createStore, createLogger } from 'vuex'

import { store as userConnect, UserConnectStore, State as UserConnectState } from '@/store/modules/user/connect';

import { store as transactionBuy, TransactionBuyStore, State as TransactionBuyState } from '@/store/modules/transaction/buy';
import { store as transactionConnect, TransactionConnectStore, State as TransactionConnectState } from '@/store/modules/transaction/connect';
import { store as transactionList, TransactionListStore, State as TransactionListState } from '@/store/modules/transaction/list';

import { store as transactionNotification, TransactionNotificationStore, State as TransactionNotificationState } from '@/store/modules/transaction/notification';

//import { kuzzleStorePlugin } from '@/services';


export type RootState  = {
    userConnect: UserConnectState;
    transactionBuy: TransactionBuyState;
    transactionConnect: TransactionConnectState;
    transactionList: TransactionListState;
    transactionNotification: TransactionNotificationState;
};

//export type Store = AuthStore<Pick<State, 'auth'>> &  DomainStore<Pick<State, 'domain'>>
export type Store = UserConnectStore<Pick<RootState,'userConnect'>> 
                    & TransactionBuyStore<Pick<RootState,'transactionBuy'>>
                    & TransactionConnectStore<Pick<RootState,'transactionConnect'>>
                    & TransactionListStore<Pick<RootState,'transactionList'>>
                    & TransactionNotificationStore<Pick<RootState,'transactionNotification'>>;

export const store = createStore({
    plugins:
      process.env.NODE_ENV === 'production'
        ? []
        : [createLogger()],
    modules: {
        userConnect,
        transactionBuy,
        transactionConnect,
        transactionList,
        transactionNotification,
    },
  });

  export function useStore(): Store {
    return store as Store
  }
  
  export default store;