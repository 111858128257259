import { MutationTree } from 'vuex';

import { State } from './state';
import { MutationTypes } from './mutation-types';

import { purchases, updateRepsonse } from '@/types';
import { SearchResult, KHit, KDocumentContentGeneric} from "kuzzle-sdk";

export type Mutations<S = State> = {
    [MutationTypes.RESET](state: S): void;
    [MutationTypes.SET_ITEMS](state: S, payload: Array<purchases>): void;
    [MutationTypes.SET_ERROR](state: S, payload: string | null): void;
    [MutationTypes.SET_NEXT](state: S, payload: Promise<SearchResult<KHit<KDocumentContentGeneric>> | null>): void;
    [MutationTypes.TOGGLE_LOADING](state: S): void;
    [MutationTypes.SET_TOTAL](state: S, payload: number): void;
    [MutationTypes.UPDATE_ITEM](state: S, payload: updateRepsonse<purchases>): void;
    [MutationTypes.SET_ROOM](state: S, payload: string | null): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state: State) {
    state.items = [];
  },
  [MutationTypes.SET_ITEMS](state: State, payload: Array<purchases>) {
    state.items = state.items.concat(payload);
  },
  [MutationTypes.SET_ERROR](state: State, payload: string | null) {
    state.error = payload;
  },
  [MutationTypes.SET_NEXT](state: State, payload: Promise<SearchResult<KHit<KDocumentContentGeneric>> | null>) {
    state.next = payload;
  },
  [MutationTypes.TOGGLE_LOADING](state: State) {
    state.isLoading = !state.isLoading;
  },
  [MutationTypes.SET_TOTAL](state: State, payload: number) {
    state.total = payload;
  },
  [MutationTypes.UPDATE_ITEM](state: State, payload: updateRepsonse<purchases>) {
    if(payload.action === 'update'){
      const item = payload.result._source;
      const index = state.items.findIndex(x => x.txhash === item.txhash);
      state.items[index] = payload.result._source;
    }else{
      state.items = state.items.concat(payload.result._source);
    }
  },
  [MutationTypes.SET_ROOM](state: State, payload: string | null) {
    state.room = payload;
  },
};