import { MutationTree } from 'vuex';

import { State } from './state';
import { MutationTypes } from './mutation-types';
import { secureStorageCookie } from '@/services';

export type Mutations<S = State> = {
    [MutationTypes.RESET](state: S): void;
    [MutationTypes.SET_ADDRESS](state: S, payload: string): void;
    [MutationTypes.SET_ERROR](state: S, payload: string | null): void;
    [MutationTypes.SET_VIOLATIONS](state: S, payload: string): void;
    [MutationTypes.TOGGLE_LOADING](state: S): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state: State) {
    state.address = null;
    secureStorageCookie.removeCookie('address');
  },
  [MutationTypes.SET_ADDRESS](state: State, payload: string) {
    state.address = payload;
    secureStorageCookie.setCookie('address', payload, 0);
  },
  [MutationTypes.SET_ERROR](state: State, payload: string | null) {
    state.error = payload;
  },
  [MutationTypes.SET_VIOLATIONS](state: State, payload: string) {
    state.violations = payload;
  },
  [MutationTypes.TOGGLE_LOADING](state: State) {
    state.isLoading = !state.isLoading;
  },
};