
import {
  IonImg,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import { ethEnabled } from "@/services";
import { defineComponent } from "vue";
import TransactionConnectButton from '@/components/transaction/ConnectButton.vue';

export default defineComponent({
  name: "LoginPage",
  components: {
    IonImg,
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonCardHeader,
    IonCardTitle,
    TransactionConnectButton
  },
  computed: {},
  setup() {
    ethEnabled();
  },
});
