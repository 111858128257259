
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
//import { inject } from 'vue';

import MainLayout from "@/components/layouts/Main.vue";
import FullLayout from "@/components/layouts/Full.vue";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    MainLayout,
    FullLayout
  },
  data(){
    return{
    }
  },
  computed: {
    layout() {
      
      //check if a layout has been specified
      if (this.$route.meta.layout) {
        return this.$route.meta.layout;
      }
      return "main-layout";
    },
  },
  setup(){
    const router = useRouter();
    
    return {
      router,
    };
  }
});
