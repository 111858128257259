
import { mapGetters, mapActions } from "vuex";
import { useRouter } from "vue-router";
import { ActionTypes } from "@/store/modules/transaction/list/action-types";
import {
  IonLabel,
  IonItem,
  IonList,
  IonContent,
  IonPage,
  IonCard,
  IonBadge,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  InfiniteScrollCustomEvent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { weiToEth } from "@/services";
import { ETHERSCAN_URL, DISCORD_URL, IMX_MARKETPLACE_URL, IMX_CONTRACT } from "@/config";

export default defineComponent({
  name: "TransactionList",
  components: {
    IonLabel,
    IonItem,
    IonList,
    IonContent,
    IonPage,
    IonCard,
    IonBadge,
    IonButton,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters("transactionList", [
      "items",
      "isLoading",
      "error",
      "violations",
      "next",
      "total",
    ]),
    ...mapGetters("transactionConnect", ["address"]),
  },
  watch: {},
  methods: {
    ...mapActions("transactionList", [
      ActionTypes.GET_ITEMS,
      ActionTypes.GET_NEXT,
      ActionTypes.CLEAR_ITEMS,
      ActionTypes.UPDATE_ITEM,
      ActionTypes.LISTEN_ITEM_UPDATE
    ]),
    openEtherscan(txhash: string) {
      window.open(`${ETHERSCAN_URL}${txhash}`, "_blank");
    },
    openOnIMX() {
      window.open(`${IMX_MARKETPLACE_URL}/inventory?collection=${IMX_CONTRACT}`, "_blank");
    },
    openDiscord() {
      window.open(`${DISCORD_URL}`, "_blank");
    },
    convertMilisecondsToDate(miliseconds: number) {
      if (!miliseconds) {
        return "---";
      }
      return new Date(miliseconds).toLocaleString();
    },
    convertWaiToEth(wei: string) {
      return weiToEth(wei.toString());
    },
    loadData(ev: InfiniteScrollCustomEvent) {
      this.GET_NEXT();
      ev.target.complete();
      if (this.items.length === this.total) {
        ev.target.disabled = true;
      }
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  ionViewWillEnter() {
    this.GET_ITEMS(this.address);
  },
  ionViewDidLeave() {
    this.CLEAR_ITEMS();
  },
});
