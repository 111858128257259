import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { MutationTypes } from './mutation-types';
import { ActionTypes } from './action-types';

import { requestAccounts } from '@/services';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.CONNECT](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
  [ActionTypes.DISCONNECT](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.CONNECT]({ commit }) {
    return new Promise(() => {
        commit(MutationTypes.SET_ERROR, "");
        commit(MutationTypes.TOGGLE_LOADING, undefined);
        requestAccounts().then((result) => {
          commit(MutationTypes.SET_ADDRESS, result[0]);
          commit(MutationTypes.TOGGLE_LOADING, undefined);
          return true;
        }).catch((e) => {
          commit(MutationTypes.SET_ERROR, e.message);
          commit(MutationTypes.TOGGLE_LOADING, undefined);
          return true;
        });
    });
  },
  async [ActionTypes.DISCONNECT]({ commit }) {
    return new Promise(() => {
        commit(MutationTypes.RESET, undefined);
    });
  }
};