
import {
      IonPage,
      IonContent,
      } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter, RouteLocationRaw } from 'vue-router';


export default defineComponent({
  name: 'FullLayout',
  components: {
    IonPage,
    IonContent,
  },
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
    }
  },
  methods:{
    navigate(path: RouteLocationRaw) {
      this.$router.push(path);
    }
  },
  setup() {
    const router = useRouter();
    return {
      router,
    }
  }
});
