
import { IonContent, IonPage } from '@ionic/vue';
import TransactionBuyQuantity from '@/components/transaction/BuyQuantity.vue';
import { ethEnabled } from '@/services';
import { defineComponent } from 'vue';
import { MINTING_COST } from '@/config';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage,
    TransactionBuyQuantity,
  },
  computed: {
  },
  setup(){
    ethEnabled();
    return{
      cost: MINTING_COST
    }
  }
});
