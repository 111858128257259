export const state: State = {
    error: '',
    isLoading: false,
    txHash: null,
    ethNetwork: '',
    violations: null,
    connected: false,
    remaining: 0,
}

export type State = {
    error: string | null;
    isLoading: boolean;
    txHash: string | null;
    ethNetwork: string | null;
    violations: string | null;
    connected: boolean;
    remaining: number;
}