
import { useRouter } from "vue-router";
import TransactionBuyButton from "@/components/transaction/BuyButton.vue";
import PlayingCards from "@/components/PlayingCards.vue";

import { IonButton, IonInput, IonItem } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TransactionBuyQuantity",
  components: {
    IonButton,
    TransactionBuyButton,
    IonInput,
    IonItem,
    PlayingCards,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      quantity: 1,
      interval: 0,
    };
  },
  computed: {
    quantityPrice() {
      return this.price * this.quantity;
    },
    quantityPriceString() {
      return this.quantityPrice.toFixed(2).toString();
    },
  },
  watch: {},
  methods: {
    stop() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = 0;
      }
    },
    up() {
      if (!this.interval) {
        this.interval = window.setInterval(() => {
          if (this.quantity < 10000) {
            this.quantity++;
          }
        }, 300);
      }
      if (this.quantity < 10000) {
        this.quantity++;
      }
    },
    down() {
      if (!this.interval) {
        this.interval = window.setInterval(() => {
          if (this.quantity > 1) {
            this.quantity--;
          }
        }, 300);
      }
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
});
