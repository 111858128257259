import { purchases } from "@/types";
import { SearchResult, KHit, KDocumentContentGeneric } from "kuzzle-sdk";

export const state: State = {
    error: '',
    isLoading: false,
    items: [],
    next: new Promise(() => { return null;}),
    total: 0,
    room: null
}

export type State = {
    error: string | null;
    isLoading: boolean;
    items: Array<purchases>;
    next: Promise<SearchResult<KHit<KDocumentContentGeneric>> | null>;
    total: number;
    room: string | null
}