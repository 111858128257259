import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-color-white text-size-22" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["playing-cards", _ctx.quantityClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["playing-card-container", _ctx.deckClass])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quantityOutout, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "playing-card",
          key: item
        }, [
          _createElementVNode("span", _hoisted_1, "Bird #" + _toDisplayString(_ctx.getCardNumber(item)), 1)
        ]))
      }), 128))
    ], 2)
  ], 2))
}