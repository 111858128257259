
import { mapGetters, mapActions } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes } from '@/store/modules/transaction/buy/action-types';
import { 
    IonButton,
    loadingController
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionBuyButton',
  components: { 
    IonButton
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    buttonText: {
      type: String,
      default: 'Buy Now',
    },
  }, 
  computed: {
    ...mapGetters('transactionConnect', ['address']),
    ...mapGetters('transactionBuy', ['isLoading','error','violations','txHash','connected']),
    buttonDisabled(){
      if(!this.connected){
        return true;
      }
      return false;
    }
  },
  watch: {
    isLoading(value){
      if(value){
        this.presentLoading();
      }else{
        loadingController.dismiss();
      }
    },
    txHash(value){
      if(value){
        this.router.push({ name: 'complete', params: { txhash: value } });
      }
    }
  },
  methods: {
    ...mapActions('transactionBuy',[
      ActionTypes.PURCHASE,
    ]),
    payNow(){
      this.TRANSACTION_PURCHASE({
        address: this.address,
        value: this.price,
        quantity: this.quantity
      });
    },
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Awaiting MetaMask Response',
        });
      await loading.present();
    },
  },
  setup(){
    const router = useRouter();
    return {
      router
    }
  },
});
