
import {
      IonHeader,
      IonTitle,
      IonToolbar,
      IonMenuToggle,
      IonMenuButton,
      } from '@ionic/vue';
import { defineComponent } from 'vue';
import { ellipsisHorizontalOutline, arrowBackOutline } from 'ionicons/icons';
import { useRouter } from 'vue-router';

import TransactionConnectButton from '@/components/transaction/ConnectButton.vue';

export default defineComponent({
  name: 'TopBar',
  components: {
    IonHeader, 
    IonTitle,
    IonToolbar,
    TransactionConnectButton,
    IonMenuToggle,
    IonMenuButton,
  },
  data() {
    return {
      appTitle: 'bcb',
    }
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  setup() {
    const router = useRouter();
    return {
      ellipsisHorizontalOutline,
      arrowBackOutline,
      router
    }
  }
});
