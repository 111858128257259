import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { MutationTypes } from './mutation-types';
import { ActionTypes } from './action-types';

import { DBINDEX, DBCOLLECTION, kuzzle } from '@/services';
import { SearchResult, KHit, KDocumentContentGeneric } from "kuzzle-sdk";


import { purchases, updateRepsonse } from '@/types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.GET_ITEMS](
    { commit }: AugmentedActionContext,
    address: string
  ): Promise<boolean>;
  [ActionTypes.GET_NEXT](
    { commit, state }: AugmentedActionContext
  ): Promise<boolean>;
  [ActionTypes.CLEAR_ITEMS](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
  [ActionTypes.UPDATE_ITEM](
    { commit }: AugmentedActionContext,
    payload: updateRepsonse<purchases>
  ): Promise<boolean>;
  [ActionTypes.LISTEN_ITEM_UPDATE](
    { commit, state }: AugmentedActionContext,
    address: string
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_ITEMS]({ commit }, address) {
    commit(MutationTypes.TOGGLE_LOADING, undefined);
    commit(MutationTypes.SET_ERROR, null);
    if (!kuzzle.connected) {
      await kuzzle.connect();
    }
    try {
      const results: SearchResult<KHit<KDocumentContentGeneric>> = await kuzzle.document.search(DBINDEX, DBCOLLECTION, {
        "query": {
          "match": {
            "address": address
          }
        },
        "sort": [
          { '_kuzzle_info.createdAt': 'desc' },
          '_id'
        ]
      },
        {
          "size": 10,
        }
      );
      const items: Array<purchases> = [];
      if (results) {
        results.hits.forEach((item: any) => {
          items.push(<purchases>item._source);
        });
      }
      commit(MutationTypes.SET_ITEMS, items);
      commit(MutationTypes.SET_TOTAL, results.total);
      commit(MutationTypes.SET_NEXT, results.next());
    } catch (e: any) {
      commit(MutationTypes.SET_ERROR, e.message);
      commit(MutationTypes.SET_NEXT, new Promise(() => { return null }));
    } finally {
      commit(MutationTypes.TOGGLE_LOADING, undefined);
    }
    return new Promise(() => { return true });
  },
  async [ActionTypes.GET_NEXT]({ commit, state }) {
    return new Promise(() => {
      commit(MutationTypes.TOGGLE_LOADING, undefined);
      commit(MutationTypes.SET_ERROR, null);
      kuzzle.connect().then(() => {
        state.next.then((results: any) => {
          if (results === null) {
            return false;
          }
          const items: Array<purchases> = [];
          results.hits.forEach((item: any) => {
            items.push(<purchases>item._source);
          });
          commit(MutationTypes.TOGGLE_LOADING, undefined);
          commit(MutationTypes.SET_ITEMS, items);
          commit(MutationTypes.SET_NEXT, results.next());
          return true;
        }).catch((e: any) => {
          console.log(e);
          commit(MutationTypes.TOGGLE_LOADING, undefined);
          commit(MutationTypes.SET_ERROR, e.message);
          commit(MutationTypes.SET_NEXT, new Promise(() => { return null }));
          return true;
        });
      }).catch((e) => {
        console.log(e);
        commit(MutationTypes.TOGGLE_LOADING, undefined);
        commit(MutationTypes.SET_ERROR, e.message);
        commit(MutationTypes.SET_NEXT, new Promise(() => { return null }));
        return true;
      });
    });
  },
  async [ActionTypes.UPDATE_ITEM]({ commit }, payload) {
    return new Promise(() => {
      //get payload result
      //check if payload is in the list of items
      commit(MutationTypes.UPDATE_ITEM, payload);
      //payload is in the list so update it with the new content
      return true;
    });
  },
  async [ActionTypes.CLEAR_ITEMS]({ commit }) {
    return new Promise(() => {
      commit(MutationTypes.RESET, undefined);
      return true;
    });
  },
  async [ActionTypes.LISTEN_ITEM_UPDATE]({ commit, state }, address) {
    if (state.room !== null) {
      return new Promise(() => {
        return true;
      });
    }
    if (!kuzzle.connected) {
      await kuzzle.connect();
    }
    const filters = {
      equals: {
        address: address
      }
    };
    try {
      const room = await kuzzle.realtime.subscribe(DBINDEX, DBCOLLECTION, filters, (res: any) => {
        commit(MutationTypes.UPDATE_ITEM, res);
      });
      commit(MutationTypes.SET_ROOM, room);
    } catch (e) {
      console.log(e);
    }
    return new Promise(() => {
      return true;
    });
  },
};