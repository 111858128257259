import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  error(state: State): string | null;
  isLoading(state: State): boolean;
  status(state: State): boolean;
  subscribed(state: State): boolean;
  roomId(state: State): string | boolean;
  message(state: State): string | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
    error: (state) => state.error,
    isLoading: (state) => state.isLoading,
    status: (state) => state.status,
    subscribed: (state) => state.subscribed,
    roomId: (state) => state.roomId,
    message: (state) => state.message,
};