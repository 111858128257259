import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { purchases } from '@/types';
import { KHit, KDocumentContentGeneric, SearchResult } from "kuzzle-sdk";

export type Getters = {
  error(state: State): string | null;
  isLoading(state: State): boolean;
  items(state: State): Array<purchases>;
  view(state: State): Promise<SearchResult<KHit<KDocumentContentGeneric>> | null>;
  total(state: State): number;
  room(state: State): string | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
    error: (state) => state.error,
    isLoading: (state) => state.isLoading,
    items: (state) => state.items,
    view: (state) => state.next,
    total: (state) => state.total,
    room: (state) => state.room
};