
import {
  IonPage,
  IonContent,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonMenuToggle,
  IonMenuButton,
  toastController,
  IonIcon
} from "@ionic/vue";
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { useRouter, RouteLocationRaw } from "vue-router";
import TopBar from "@/components/layouts/TopBar.vue";

import { kuzzle, DBINDEX, DBCOLLECTION } from "@/services";

import { ActionTypes } from "@/store/modules/transaction/list/action-types";
import { ActionTypes as BuyActionTypes } from "@/store/modules/transaction/buy/action-types";


import { alertCircleOutline } from "ionicons/icons";

export default defineComponent({
  name: "MainLayout",
  components: {
    IonPage,
    IonContent,
    TopBar,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonMenuToggle,
    IonMenuButton,
    IonIcon
  },
  data(){
    return {
      room: ''
    }
  },
  computed: {
    ...mapGetters("transactionConnect", ["address"]),
    ...mapGetters("transactionBuy", ["connected"]),
    showDisconnected(){
      if(!this.connected){
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions("transactionList", [
      ActionTypes.UPDATE_ITEM,
      ActionTypes.GET_ITEMS
      ]),
      ...mapActions("transactionBuy", [
      BuyActionTypes.LISTEN_FOR_CONNECTION
      ]),
    navigate(path: RouteLocationRaw) {
      this.$router.push(path);
    },
    callback(res: any) {
      this.UPDATE_ITEM(res);
      this.openToastOptions(
        res.result._source.message,
        res.result._source.txhash
      );
    },
    async openToastOptions(message: string, hash: string) {
      const toast = await toastController.create({
        header: hash,
        position: "top",
        message: message,
        duration: 3000,
      });
      await toast.present();
    },
    delay(t: number, v: any) {
      return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t);
      });
    },
    async listen(address: string) {
      if (!kuzzle.connected) {
        await kuzzle.connect();
      }
      if(this.room){
        return;
      }
      const filters = {
        equals: {
          address: address,
        },
      };
      this.room = await kuzzle.realtime.subscribe(
        DBINDEX,
        DBCOLLECTION,
        filters,
        this.callback
      );
      //Trick to get items to return on reload. not sure why the listen call stops the get items call
      this.GET_ITEMS(this.address);
    },
    async unsubcribe(){
      if(!this.room){
        return;
      }
      await kuzzle.realtime.unsubscribe(this.room);
    }
  },
  mounted() {
    this.listen(this.address);
    this.TRANSACTION_LISTEN_FOR_CONNECTION();
  },
  unmounted(){
    this.unsubcribe();
  },
  setup() {
    const router = useRouter();
    return {
      alertCircleOutline,
      router,
    };
  },
});
